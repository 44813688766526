<template>
    <v-data-table 
        v-model="selected"
        :headers="headers" 
        :total-items="totalItems" 
        :pagination.sync="pagination" :loading="loading"
        :items="items" 
        select-all
        class="elevation-3"
        >
        <template slot="items" slot-scope="props">
            <tr
                :class="{ 'yellow lighten-1': (props.item.state == 'pago_pendiente' && $vuetify.breakpoint.smAndDown), 'green lighten-3': (props.item.state == 'pago_realizado' && $vuetify.breakpoint.smAndDown) }">
                <td class="text-xs-center   ">
                    <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details
                    ></v-checkbox>
                </td>
                <td class="text-xs-center  hidden-md-and-up ">
                    <v-speed-dial v-model="props.fab" direction="right" transition="slide-x-reverse-transition">
                        <template v-slot:activator>
                            <v-btn small v-model="props.fab" color="blue darken-2" dark fab>
                                <v-icon>account_circle</v-icon>
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                        <v-btn fab dark small color="blue" @click.stop="abrirVerInscripcion(props.item)" title="Ver Inscripción">
                            <v-icon>account_circle</v-icon>
                        </v-btn>
                        <v-btn fab dark small color="green" @click.stop="abrirEditarInscripcion(props.item)" title="Editar Inscripción">
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <v-btn fab dark small color="blue" @click.stop="abrirMarcarPagada(props.item)" title="Marcar Pagada">
                            <v-icon>attach_money</v-icon>
                        </v-btn>
                        <v-btn fab dark small color="red" @click.stop="abrirBorrarInscripcion(props.item)" title="Borrar Inscripción">
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </v-speed-dial>
                </td>

                
                <td class="text-xs-center  hidden-sm-and-down">{{ props.item.id }}</td>
                <td class="text-xs-left">{{ getDocumento(props.item) }}</td>
                <td class="text-xs-center">{{ getNombreYApellido(props.item) }}</td>
                <td class="text-xs-center hidden-sm-and-down">{{ props.item.modalidad.nombre }}
                </td>
                <td class="text-xs-center hidden-sm-and-down ">{{ getCategoria(props.item) }}
                </td>
                <td class="text-xs-center hidden-md-and-up">{{ props.item.modalidad.nombre }}/{{
                    getCategoria(props.item) }}</td>
                <td class="text-xs-center  hidden-sm-and-down">{{ getCiudad(props.item) }}</td>
                <td class="text-xs-center  hidden-sm-and-down">{{ getFechaNacimiento(props.item)
                }}</td>
                <td class="text-xs-center  hidden-sm-and-down">{{ getSexo(props.item) }}</td>
                <td class="text-xs-center  hidden-sm-and-down">{{ getCondiscapacidad(props.item) }}</td>
                <td  class="text-xs-center hidden-sm-and-down">
                    <LabelEstado  :state="props.item.state" />
                </td>
                <td class="text-xs-center hidden-sm-and-down">
                    {{ props.item.created_at | formatDate }}
                </td>
                <td class="text-xs-center  hidden-sm-and-down" v-if=evento.usa_pago_online>
                    <PagoTooltip :inscripcion="props.item" @click="abrirInformacionDePago"/>
                </td>

                <td class="text-xs-center  hidden-sm-and-down">                    
                    <DescuentoTooltip :cupon="props.item.cupon_descuento" />
                </td>
                <td class="text-xs-center  hidden-sm-and-down" v-if="hasInscriptionsWithNumber" >  
                    {{ props.item.numero }}                  
                </td>
                <td class="text-xs-center hidden-sm-and-down">
                    <v-icon @click="abrirVerInscripcion(props.item)" title="Ver Mas" small>info</v-icon>
                    <v-icon @click.stop="abrirEditarInscripcion(props.item)" title="Editar" small>edit</v-icon>
                    <v-icon @click.stop="abrirBorrarInscripcion(props.item)" title="Borrar" small>delete</v-icon>
                    <v-icon @click.stop="abrirMarcarPagada(props.item)" title="Marcar Pagada" small>attach_money</v-icon>
                </td>
                

            </tr>
        </template>

        <template slot="no-data">
            <v-alert :value="true" color="warning" icon="sentiment_dissatisfied">
                <span v-if="!loading">No hay inscripciones que coincidan con la búsqueda.</span>
                <span v-if="loading">Cargando...</span>
            </v-alert>
        </template>
        <template slot="pageText" slot-scope="props">
            Inscriptos desde {{ props.pageStart }} a {{ props.pageStop }} de {{
                props.itemsLength }}
        </template>
    </v-data-table>
</template>

<script>
import inscripcionTablaMixins from '@/mixins/utils';
import LabelEstado from '@/components/common/LabelEstado.vue';
import DescuentoTooltip from '@/components/common/DescuentoTooltip.vue';
import PagoTooltip from '@/components/common/PagoTooltip.vue';

export default {
    data(){
        return {
            
            pagination: {                
                descending: false,
                page: 1,
                rowsPerPage: 20,
                sortBy: "id", 
            },
            selected: [],
        }
    },
    
    mixins: [inscripcionTablaMixins],
    components: {
        LabelEstado,
        DescuentoTooltip,
        PagoTooltip,
    },
    props: {
        loading: Boolean,
        items: Array,
        totalItems: Number,
        evento: Object, 
    },

    created()  {     
    
    },

    watch:{
        evento:{
            handler() {
                //console.log("PASA")
            }
        },
        pagination: {
            handler() {
                
                this.$emit('paginationChange', this.pagination);
            },
            deep: true,
        },
    },

    methods: {
        abrirEditarInscripcion(inscripcion) {
            this.$emit('abrirEditarInscripcion', inscripcion);
        },

        abrirVerInscripcion(inscripcion) {
            this.$emit('abrirVerInscripcion', inscripcion);
        },
        abrirInformacionDePago(merchantOrder) {             
            this.$emit('abrirInformacionDePago', merchantOrder);
        },
        abrirBorrarInscripcion(inscripcion){
            this.$emit('abrirBorrarInscripcion', inscripcion);           
        },
        abrirMarcarPagada(inscripcion){
            this.$emit('abrirMarcarPagada', inscripcion);           
        },
        getSelected(){
            return this.selected;
        }
    },
    computed: {
        hasInscriptionsWithNumber() {
            return this.items.some(inscripcion => inscripcion.numero);
        },
        headers(){
            let headers = 
            headers = [
                    { text: 'Acc', class: 'hidden-md-and-up', value: 'acciones', align: 'center', sortable: false },
                    { text: 'Id', class: 'hidden-sm-and-down', align: 'center', sortable: true, value: 'id' },
                    { text: 'Doc', align: 'left', sortable: false, value: 'documento' },
                    { text: 'Nombre/Apellido', align: 'center', sortable: false, value: 'nombre' },
                    { text: 'Modalidad', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'modalidad_id' },
                    { text: 'Categoria', class: 'hidden-sm-and-down', align: 'center', sortable: false },
                    { text: 'Mod/Cat', class: 'hidden-md-and-up', align: 'center', sortable: false },
                    
                    { text: 'Ciudad', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'ciudad' },
                    { text: 'Fecha Nac', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'fecha_nacimiento' },
                    { text: 'Sexo', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'sexo' },
                    { text: 'Discapacidad', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'conDiscapacidad' },
                    { text: 'Estado', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'state' },
                    { text: "Fecha Inscripción", class: 'hidden-sm-and-down', align: 'center', sortable: false, value: "created_at"},
                ];

            if (this.evento.usa_pago_online) {
                headers = [
                    ...headers, 
                    { text: "MedioPago", class:  'hidden-sm-and-down', align: 'center', sortable: false, value: "merchant_order"},
                    ]
            }
            
            headers = [...headers,{ text: "Cupon Descuento" , class:  'hidden-sm-and-down', align: 'center', sortable: false, value: "cupon"}]

            if(this.hasInscriptionsWithNumber) {
                headers.push( { text: 'Número', class: 'hidden-sm-and-down', align: 'center', sortable: false, value: 'numero' });
            }

            headers.push({ text: 'Acciones', class: 'hidden-sm-and-down', value: 'acciones', align: 'center', sortable: false });
            return headers;
        }
    }
}
</script>