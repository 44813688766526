import axios from 'axios'
import auth from './auth'
import config from '../config'
export default {

    getFrontEventoById(id){       
        let url = config.api_eventos_get.replace('ID',id) 
        return axios.get( url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    },

    getFrontEventoByIdAndCodigoAcceso(id, codigo_acceso){       
        let url = config.api_eventos_privado_get.replace('ID',id).replace('CODIGO_ACCESO',codigo_acceso) 
        return axios.get( url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    },

    getBackofficeEventoById(id){       
        let url = config.api_eventos_show.replace('ID',id) 
        return axios.get( url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    }, 
    crearEvento(parameters){
        return axios.post(config.api_eventos_create, parameters)
            .then((res) => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },

    actualizarEvento(id,parameters){
        let url = config.api_eventos_update.replace('ID',id) 
        return axios.put(url, parameters)
            .then((res) => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },

    getCustomFields(id){
        let url = "modalidad/171/campos-custom".replace('ID',id) 
        return axios.get( url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    },
    
    borrarEvento(id, verificacion){
      let url = 'eventos/' + id;
      return axios.delete(url, {
                data: {
                    verificacion: verificacion
                }
            })
            .then(res => res.data)
            .catch(res => { 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                    else{
                        throw res; //lanzara una excpeción, la captura el catch mas cercano en la cadena de Promise en el retorno
                    }
                }
            })
	}

}
