<template>
    <v-dialog v-model="dialogAbierto" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="60%">
        <v-card v-if="inscripcion">
            <v-card-title>
                <span class="headline">Registrar pago</span>
            </v-card-title>

            <v-card-text>
                <v-container elevation-3 py-2 px-1>
                    <v-form autocomplete="off">
                        <v-layout row wrap>
                            <v-flex xs12 mb-3 pa-2>
                                <h3 class="headline ">{{ (inscripcion.personas.length > 1 ? 'Equipo' : 'Participante')
                                    }}</h3>
                                <span class="subheading">
                                    {{ inscripcion.personas.length > 1 ? inscripcion.nombre :
                                        inscripcion.personas[0].nombre + ' '
                                        + inscripcion.personas[0].apellido + ' (' + inscripcion.personas[0].documento + ')'
                                    }}
                                </span>
                                <v-divider></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout my-2 wrap>


                            <v-flex xs12 md6 pa-2>
                                <v-select v-validate="'required'" :error-messages="errors.collect('medio_pago')"
                                    data-vv-name="medio_pago" v-model="medio_pago" :items="medios_pago" item-text="name"
                                    item-value="value" label="Medio de Pago">
                                </v-select>
                            </v-flex>
                            <v-flex xs12 md6  pa-2>
                                <v-text-field v-validate="'required'" :error-messages="errors.collect('monto_pagado')"
                                    name="monto_pagado" v-model="monto_pagado" data-vv-name="monto_pagado"
                                    label="Monto Pagado" prepend-icon="attach_money"></v-text-field>
                            </v-flex>
                            <v-flex xs12 md6 pa-2>
                                <v-menu ref="menuDate1" :close-on-content-click="false" v-model="menuDate1"
                                    :nudge-right="40" lazy transition="scale-transition" offset-y full-width
                                    min-width="290px">

                                    <v-text-field v-validate="'required'" :error-messages="errors.collect('fecha_pago')"
                                        name="fecha_pago" slot="activator" v-model="fechaPagoFormateada"
                                        data-vv-name="fecha_pago" label="Fecha del pago"
                                        prepend-icon="event"></v-text-field>

                                    <v-date-picker ref="picker" v-model="fechaPago" min="2017-01-01"
                                        @change="saveDateInicio" locale="es-ar"></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 md6 pa-2>

                                <v-text-field
                                    label="Hora del pago"
                                    value="00:01"
                                    type="time" 
                                    v-model="hora_pago"
                                    prepend-icon="access_time"
                                ></v-text-field>

                                <!-- <v-menu  ref="menuTime" v-model="menuTime" :close-on-content-click="false" :return-value.sync="hora_pago" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                                    <v-text-field v-validate="'required'" :error-messages="errors.collect('hora_pago')" name="hora_pago" slot="activator" v-model="hora_pago" data-vv-name="hora_pago" label="Hora del pago" prepend-icon="access_time"></v-text-field>
                                    <v-time-picker ref="timePicker" v-model="horaPago" format="24hr" @change="$refs.menuTime.save(horaPago)"></v-time-picker>
                                </v-menu>

                         -->

                            </v-flex>
                           
                            <v-flex xs12 pa-1>
                                <v-card>
                                    <v-card-title>
                                        <span class="subheading font-weight-bold">Detalle de monto a abonar</span>
                                    </v-card-title>
                                    <div>
                                        <v-flex xs12>
                                            <v-layout row wrap justify-center>
                                                <v-flex xs12 pa-3 style="background-color: rgba(255, 157, 0, 0.182);">

                                                    <v-layout row wrap justify-space-between>
                                                        <v-flex xs6>Valor</v-flex>
                                                        <v-flex xs6 text-xs-right
                                                            :class="{ 'tachado': datos_pago.codigo }">
                                                            <span> $ {{ datos_pago.valor_modalidad }}</span>
                                                        </v-flex>
                                                    </v-layout>

                                                    <v-layout v-if="datos_pago.codigo" row wrap justify-space-between>
                                                        <v-flex xs6>Valor con descuento</v-flex>
                                                        <v-flex xs6 text-xs-right>
                                                            <span> $ {{ datos_pago.valor }}</span>
                                                        </v-flex>
                                                    </v-layout>



                                                    <v-layout row wrap justify-space-between
                                                        v-if="datos_pago.costo_servicio > 0">
                                                        <v-flex xs6>Costo servicio FinishTime</v-flex>
                                                        <v-flex xs6 text-xs-right> $ {{ datos_pago.costo_servicio }}
                                                        </v-flex>
                                                    </v-layout>

                                                    <v-layout v-if="datos_pago.codigo" row wrap justify-space-between>
                                                        <v-flex xs6>Cupón aplicado</v-flex>
                                                        <v-flex xs6 text-xs-right> <span>{{ datos_pago.codigo }}
                                                            </span></v-flex>
                                                    </v-layout>

                                                    <v-layout row wrap justify-center>
                                                        <v-flex xs6 class="title font-weight-black">Total</v-flex>
                                                        <v-flex xs6 text-xs-right class="title font-weight-bold">$ {{
                                                            datos_pago.total }}</v-flex>
                                                    </v-layout>

                                                </v-flex>
                                            </v-layout>
                                        </v-flex>

                                    </div>
                                </v-card>
                            </v-flex>

                        </v-layout>


                    </v-form>
                </v-container>


            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="submitting" color="secondary darken-1" flat @click="cerrarDialog">Cancelar</v-btn>
                <v-btn :loading="submitting" color="primary" flat @click="guardarDatos()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import api from "../../../api/inscripcion";
import CanAccess from "../../utils/CanAccess.vue";
import LabelEstado from "../../common/LabelEstado.vue";
export default {
    props: {
        inscripcion: {
            type: Object,
            required: true,
        },
    },
    components: { CanAccess: CanAccess, LabelEstado: LabelEstado },
    data() {
        return {
            submitting: false,
            datos_pago: {
                valor: 0,
                costo_servicio: 0,
                total: 0,
                codigo: "",
            },
            costo_servicio: "",
            valor: "",
            total: "",
            cupon: "",
            monto_pagado: null,
            medio_pago: null,
            fechaPagoFormateada: "",
            horaPago: null,
            hora_pago: "00:00",
            menuTime: false,
            fechaPago: null,
            menuDate1: null,
            medios_pago: [
                { name: "Transferencia Bancaria", value: "bank_transfer" },
                { name: "Efectivo", value: "cash" }
            ],
            dialogAbierto: false,
            inscripcionEditar: Vue.util.extend({}, this.inscripcion),
        };
    },
    computed: {
        ...mapGetters("auth", [
            "isLoggedIn",
            "isAdmin",
            "isOrganizador",
            "nombreUsuario",
        ]),
        ...mapState({
            evento: (state) => state.eventos.eventoActual,
            modalidades: (state) => state.eventos.eventoActual.modalidades,
            estados_inscripcion: (state) => state.inscripcion.estados_inscripcion,
        }),
    },
    created() { },
    watch: {
        inscripcion: {
            immediate: true,
            handler(newVal, oldVal) {
                this.inscripcionEditar = Vue.util.extend({}, newVal)
                if (this.inscripcionEditar && this.inscripcionEditar.id) {
                    this.cargarDatos();
                }
            },
        },
        fechaPago() {
            this.fechaPagoFormateada = this.formatDate(this.fechaPago)
        }
    },
    mounted() { },
    methods: {

        async cargarDatos() {

            const data = await api.getInscripcionConMerchantOrder(this.inscripcionEditar.id)
            console.log(data)

            if (!data || data.status != 200) {
                alert("Ocurrio un error")
                this.valor = null;
                this.costo_servicio = null;
                this.total = null;
            } else {
                const { datos_pago } = data.results;
                this.datos_pago = datos_pago;
                this.monto_pagado = datos_pago.total;
                this.medio_pago = "bank_transfer";
            }

        },


        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        saveDateInicio(date) {
            this.$refs.menuDate1.save(date)
        },
        abrirDialog() {
            this.dialogAbierto = true;

        },
        cerrarDialog() {
            this.dialogAbierto = false;
            this.resetFields();
        },
        resetFields() {
            this.monto_pagado = null;
            this.medio_pago = null;
            this.fechaPago = null;
            this.fechaPagoFormateada = null;
            this.hora_pago = null;
            this.horaPago = null;
        },
        async guardarDatos() {

            this.$validator.validateAll().then(async (isOk) => {
                if (isOk) {
                    this.submitting = true;
                    const result = await api.registrarPago(this.inscripcionEditar.id, this.fechaPago, this.hora_pago, this.medio_pago, this.monto_pagado);
                    this.submitting = false;

                    if (!result || !result.isOk) {
                        this.$store.commit('app/SET_MESSAGE', result.message);
                        this.$store.commit('app/SET_SHOW', true);
                    }
                    this.cerrarDialog();
                    this.$emit("refrescarDatos");
                }
            });
        },

    },
};
</script>
