<template>
     
    <v-layout align-center justify-center>
        <v-flex>
           
            <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
            <v-btn color="orange" rigth to="/admin/evento/nuevo"  >
                Crear Evento
                <v-icon right small>library_add</v-icon>
            </v-btn> 
            
            <!---  --> 
            <v-dialog v-model="dialogBorrar" max-width="600">
                <v-card>
                    <v-toolbar dark color="orange">
                      <v-btn icon dark @click="dialogBorrar = false">
                          <v-icon>close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Borrar Evento</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text v-if="eventoBorrar.total_inscriptos">
                    <v-icon>warning</v-icon> Este evento tiene {{ eventoBorrar.total_inscriptos }} inscriptos 
                    </v-card-text>
                  
                    <v-card-text>
                        Para eliminar el evento, se necesita ingresa el <b>nombre exacto
                        del evento</b> en el cuadro de verificación y luego confirmar.
                        <v-text-field 
                            :value="eventoBorrar.nombre"
                            label="Nombre exacto"
                            disabled>
                        </v-text-field>
                        <v-form @submit.prevent autocomplete="off">
                        <v-text-field
                            v-model="match"
                            label="Verificación"
                            outline
                            data-vv-name="verificación"
                            v-validate="'required'"
                            :error-messages="errors.collect('verificación')"
                            >   
                              
                        </v-text-field>   
                              
                        </v-form>
                    </v-card-text> 
                                                                      
                    <v-card-actions>
                        <v-spacer></v-spacer>    
                        <v-btn  @click='dialogBorrar=false'>cancelar</v-btn>  
                        <v-btn color="orange" outline :loading=loadingSubmit @click='confirmarBorradoEvento()'>confirmar</v-btn>     
                    </v-card-actions>                   
                </v-card>
            </v-dialog>
            <!---  -->  
            <section>
                <v-card>
                    <v-card-title>
                      <h1 class="mx-2">Eventos</h1>    
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="pagination.search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mx-2"
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :total-items="totalItems"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :items="items" 
                    class="elevation-3"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.id }}</td>
                        <td class="text-xs-left">{{ props.item.nombre }}</td>
                        <td class="text-xs-left">{{ props.item.fecha_hora | formatDateTime }}</td>
                        <td class="text-xs-left">{{ props.item.ciudad }}</td>
                        <td class="text-xs-left">{{ props.item.organizador.nombre }}</td>
                        <td class="text-xs-left">{{ props.item.fin_inscripcion | formatDateTime }}</td>
                        <td class="text-xs-center">{{ props.item.total_inscriptos }}</td>
                        <td class="text-xs-center">
                            <v-icon v-if="props.item.usa_pago_online" alt="editar" small color="green" > brightness_1</v-icon>
                            <v-icon v-if="!props.item.usa_pago_online" alt="editar" small > brightness_1</v-icon>
                        </td>
                        <td class="text-xs-center">
                            <v-icon v-if="props.item.visible" alt="editar" small color="green" > brightness_1</v-icon>
                            <v-icon v-if="!props.item.visible" alt="editar" small > brightness_1</v-icon>
                        </td>
                        <td class="text-xs-center">
                            <v-btn title="Editar" flat  icon  :to="'/admin/evento/'+props.item.id+'/editar'">
                                <v-icon alt="editar" small > edit</v-icon>
                            </v-btn>
                            <v-btn title="Ver Inscriptos"  :to="'/backoffice/event/'+props.item.id" icon  flat    >
                                <v-icon small  >notes</v-icon>
                            </v-btn>
                            <v-btn   title="Nueva inscripción"  :to="'/admin/evento/'+props.item.id+'/inscripcion'" icon  flat    >
                                <v-icon small>person_add</v-icon>
                            </v-btn>
                            <v-btn   title="Agregar clasificacion y fotos"  :to="'/admin/evento/'+props.item.id+'/adjuntos'" icon  flat    >
                                <v-icon small>file_upload</v-icon>
                            </v-btn>
                            <CanAccess :roles="['admin']">
                                <v-btn flat icon @click="openDialogBorrado(props.item)">
                                  <v-icon>delete</v-icon>    
                                </v-btn>
                            </CanAccess>
                           
                        </td>
                    </template>
                    <template slot="no-data">
                        <v-alert :value="true" color="warning" icon="warning">
                            <span v-if="!loading">Sin Eventos aún.</span>
                            <span v-if="loading">Cargando...</span>
                        </v-alert>
                    </template>
                </v-data-table>
                </v-card>
            </section>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex' 
import api from '../../api/evento'
import  VeeValidate,{Validator} from 'vee-validate'; 
import Vue from 'vue'
import CanAccess from '../utils/CanAccess.vue';

Vue.use(VeeValidate);

export default {
    components: {
        CanAccess
    },
    data () {
        return { 
            loading:null,
            loadingSubmit: false,
            dialogBorrar: false,
            eventoBorrar: {},
            match:'',
            steps:[                 
                {
                    text: 'Eventos',
                    disabled: false,
                    href: '/#/admin/evento/lista'
                }],
            pagination: {
                sortBy:'id',
                descending:true,
                search:null
            },
            headers: [
                {text:'Id', align:'center', sortable:true, value:'id'},
                {
                    text: 'Nombre Evento',
                    align: 'left',
                    sortable: true,
                    value: 'nombre'
                },
                { text: 'Fecha', value: 'fecha_hora',   align: 'left'},
                { text: 'Ciudad', value: 'ciudad' },
                { text: 'Organizador', value: 'organizador_id' },
                { text: 'Cierre inscripcion', value: 'fin_inscripcion' },
                { text: 'Inscriptos', value: 'total_inscriptos',align: 'center' },
                { text: 'MercadoPago', value: 'usa_pago_online',align: 'center' },
                { text: 'Visible', value: 'visible',align: 'center' },
                { text: 'Acciones', value: 'acciones', align: 'center',sortable: false },
            ],
            
        }
    },
    computed: mapState({
        items: state => state.eventos.eventos_listado? state.eventos.eventos_listado.data:[],
        totalItems: state => state.eventos.eventos_listado? state.eventos.eventos_listado.total:0
    }),
    created () {
        scroll(0,0);
        //this.$store.dispatch('eventos/getEventosListado', {filter:{},page:1})
    },
    watch:{
        pagination:{
            handler(){
                this.loading = true;
                this.getDataFromApi() 
            },
            deep: true
        }        
    },
    mounted(){
        this.loading = true;
        //this.getDataFromApi()
    },
    methods:{
        getDataFromApi(){
            this.loading = true;
            return this.$store.dispatch('eventos/getEventosListado',  this.pagination)
            .then((res)=>{
                this.loading = false;
            })
        },
        openDialogBorrado(evento){
                this.$validator.reset();
                this.dialogBorrar = true;
                this.match ='';
                this.eventoBorrar = evento;
        },
        
        confirmarBorradoEvento(){      
            this.$validator.validateAll()
            .then(isOk => {
                if (isOk){
                    this.loadingSubmit = true;  
                    api.borrarEvento(this.eventoBorrar.id, this.match)
                    .then(res =>{
                        this.handleDeleteSuccess(res)}
                    )
                    .catch(error => {
                        this.handleDeleteError(error.response);
                    }); 
                }
            })          
            
            
            
            
        },

        handleDeleteSuccess(res) {
            this.getDataFromApi();
            this.loadingSubmit = false;
            this.dialogDisciplina = false;
            this.$store.commit('app/SET_MESSAGE', res.message);
            this.$store.commit('app/SET_TYPE', 'success');
            this.$store.commit('app/SET_SHOW', true);
            this.dialogBorrar = false;
            
		},

        handleDeleteError(error) {
            this.loadingSubmit = false;;
            this.$store.commit('app/SET_MESSAGE', error.data.message);
            this.$store.commit('app/SET_TYPE', 'error');
            this.$store.commit('app/SET_SHOW', true);
        },
    }
}
</script>                   
