import axios from 'axios'
import config from '../config'
import auth from './auth'
export default {

    sendEmails(parameters){
        return axios.post(config.api_send_emails_url, parameters)
            .then((res) => res.data)
            .catch(res=>console.log(res)); 
    },
    
    getEventosVisibles () {        
        return axios.get('v1/public/eventos/visibles')
                .then(res=> res.data)
                .catch(res=>console.log(res)); 
    },

    getInscripcionById(id){
        let url = "inscripciones/"+id
        return axios.get(url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    },

    getInscripcionConMerchantOrder(id){
        let url = "inscripciones/"+id+"/merchant_order"
        return axios.get(url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    },

    registrarPago(inscripcionId, fechaPago,horaPago, medio_pago, monto){
        let url = "inscripciones/"+inscripcionId+"/registrar_pago"
        return axios.post(url, {id:inscripcionId, date_approved: fechaPago, time_approved: horaPago, payment_method_id: medio_pago, ammount: monto })
            .then(res=> res.data)
            .catch(res=>console.log(res));
    },

    getEventosFechaDesde (fecha_desde, fecha_hasta) {           
        var options = {};
        if(fecha_desde){
            options.params = {
                'fecha_desde':fecha_desde
            };
            if(fecha_hasta){
               options.params.fecha_hasta = fecha_hasta; 
            }
        } 
        return axios.get(config.api_eventos_visibles_url, options)
                .then(res=> res.data)
                .catch(res=>console.log(res)); 
    },

    getEventosVisiblesPasados (page) {
        var options = {};
        if(page){
            options.params = {
                'page':page,
            };
        } 
        return axios.get(config.api_eventos_visibles_pasados_url,options)
                .then(res=> res.data)
                .catch(res=>console.log(res)); 
    },

    getEventoById(id){       
        let url = config.api_eventos_show.replace('ID',id) 
        return axios.get( url)
            .then(res=> res.data)
            .catch(res=>console.log(res));
    }, 

    getEventosListadoTipo( params, tipo){
        let url = config.api_eventos_index;
        var options = {
            params:{
                'page':params.page,
                'direction':params.descending?'DESC':'ASC',
                'sortBy':params.sortBy,
                'rowsPerPage':params.rowsPerPage
            }
        }; 
        
        if(params.search){
            options.params.search = params.search
        }
        
        if(tipo == 'organizador'){
            url = config.api_eventos_list_organizador;
        }
        
        return axios.get(url, options)
            .then(res=> res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },
    getEventosListado( params){
       return this.getEventosListadoTipo(params, 'admin');
    },

    getEventosOrganizadorListado( params){
        return this.getEventosListadoTipo(params, 'organizador') ;
    },

    getOrganizadoresListado( params){ 
        var options = { }
        if(params){
            options.params = {               
                'page':params.page,
                'direction':params.descending?'DESC':'ASC',
                'sortBy':params.sortBy,
                'rowsPerPage':params.rowsPerPage                
            }

            if(params.search){
                options.params.search = params.search
            }
        }
        return axios.get(config.api_organizadores_list, options)
            .then(res=> res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },


    getOrganizadores( params){ 
        var options = { }
        if(params){
            options.params = {               
                'page':params.page,
                'direction':params.descending?'DESC':'ASC',
                'sortBy':params.sortBy,
                'rowsPerPage':params.rowsPerPage                
            }

            if(params.search){
                options.params.search = params.search
            }
        }
        return axios.get(config.api_organizadores_index, options)
            .then(res=> res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },

    crearOrganizador(parameters){
        return axios.post(config.api_organizadores_create, parameters)
            .then((res) => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },

    actualizarOrganizador(id,parameters){
        let url = config.api_organizadores_update.replace('ID',id) 
        return axios.put(url, parameters)
            .then((res) => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },

        
    enviarInscripcion(parameters, fromBackoffice=false) { 
        const url = config.api_inscripcion_create.concat((fromBackoffice)?'?fromBackoffice=true':'');
        return axios.post(url, parameters)
           .then(res=> res.data) 
    },

    
 


    /** NEW */
    updateInscripcion(id,parameters){
        let url = `inscripciones/${id}/doupdate`
        return axios.put(url , parameters)
           .then(res=> {return {...res.data, isOk: true }})
           .catch(res=>{ 
            if(res.response){                   
                if(res.response.status == 401){
                    auth.logout();
                }
            }
        });  
    },



    borrarInscripcion(id){
        let url = config.api_inscripcion_delete.replace('ID',id) 
        return axios.delete(url )
           .then(res=> res.data)
           .catch(res=>{ 
            if(res.response){                   
                if(res.response.status == 401){
                    auth.logout();
                }
            }
        });  
    },


    existeInscripcionParaDNI(dni, evento_id) { 
        let url = config.api_verificar_existencia_dni_evento.replace('ID',evento_id);
        return axios.post(url , {'dni':dni})
           .then(res=> res.data) 
    },

    getUltimasInscripciones(){
        return axios.get(config.api_ultimas_incripciones_url)
            .then(res=> res.data)
            .catch(res=>console.log(res));  
    },

    getProximosEventos(){
        return axios.get(config.api_proximos_eventos_url)
            .then(res=> res.data)
            .catch(res=>console.log(res));  
    },
    

    

    getInscriptosEventoTypeId(id, params,type){
        console.log(params)
        var options = {
            params:{
                'page':params.page,
                'direction':params.descending?'DESC':'ASC',
                'sortBy':params.sortBy,
                'rowsPerPage':params.rowsPerPage,
                'filters':params.filters 
            }            
        }; 
        let url = config.api_inscriptos_evento.replace('ID',id) 
        if(type == 'organizador'){
            url = config.api_inscriptos_evento_organizador_url.replace('ID',id) 
        }
        if(params.search){
            options.params.search = params.search
        }
        
         //@todo: armar la url del request a mano a partir de los parametros params
        return axios.get(url, options)
            .then( res => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },

    getInscriptosEventoId(id, params){
        return this.getInscriptosEventoTypeId(id, params, 'admin');
    },
    getInscriptosEventoOrganizadorId(id, params){
        return this.getInscriptosEventoTypeId(id, params,'organizador');
    },

    getAllInscriptosEventosAsignarNumero(id, params){
        console.log(params)
        var options = {
            params:{
                'page':params.page,                
                'rowsPerPage':params.rowsPerPage,
                'filters':params.filters 
            }            
        }; 
       
        let url = `eventos/${id}/inscriptos-para-asignar-numero`
        return axios.get(url, options)
            .then( res => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },


    /**
     * Organizador
     */

    getMisProximosEventos(){
        return axios.get(config.api_proximos_eventos_organizador_url)
            .then(res=> res.data)
            .catch(res=>console.log(res));  
    },

    getMisUltimasInscripciones(){
        return axios.get(config.api_ultimas_incripciones_organizador_url)
            .then(res=> res.data)
            .catch(res=>console.log(res));  
    },

    getInfoPerfil(){
        return axios.get(config.api_perfil_url)
            .then(res=> res.data)
            .catch(res=>console.log(res));  
    },

    actualizarPerfil(params){
        return axios.put(config.api_perfil_url, params)
        .then(res=> res.data)
        .catch((res) => { console.log(res); return false; } );  
    },

    saveLinks(id,links){
        return axios.put('eventos/'+id+'/links', links)
            .then((res) => res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            });        
    },
    agregarCupon(inscripcionId, cupon){
        const reqBody = {"codigo":cupon};
        return axios.post('inscripciones/'+inscripcionId+'/agregar-cupon', reqBody)
            .then((res) => res.data.results)
            .catch(error =>{ 
                console.log(error);
                throw error;
            });
    },

    asignarNumeros(eventoId, modalidades){
        return axios.post('eventos/'+eventoId+'/asignar-numeros', {modalidades})
            .then((res) => res)
            .catch(error =>{ 
                console.log(error);
                throw error;
            });
    },


    asignarNumerosManual(eventoId, asignaciones){
        return axios.post('eventos/'+eventoId+'/asignar-numeros-manual', {asignaciones})
            .then((res) => res)
            .catch(error =>{ 
                console.log(error);
                throw error;
            });
    },

    resetNumeros(eventoId){
        return axios.post('eventos/'+eventoId+'/reset-asignacion', {})
            .then((res) => res)
            .catch(error =>{ 
                console.log(error);
                throw error;
            });
    },

    getModalidadesInscriptos(eventoId){
        return axios.get('eventos/'+eventoId+'/cantidad-inscriptos', )
            .then((res) => res.data.inscripciones_modalidades)
            .catch(error =>{ 
                console.log(error);
                throw error;
            });
    },

    getMensajeInscripcion(eventoId, inscripcionId){
        return axios.get('v1/eventos/'+eventoId+'/inscripciones/'+inscripcionId+'/mensaje_ok', )
            .then((res) => res.data)
            .catch(error =>{ 
                console.log(error);
                throw error;
            });
    }
} 