<style>
.modalidades-panel,.fechas-panel,.disciplinas-panel
.categorias-panel {

    background-color: #f5f4f4;
    padding: 15px;
    border-radius: 10px;
    margin: 20px 0 20px 0;
}

.modalidades-list,.disciplinas-list
.categorias-list {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
}

.modalidades-list-header,.disciplinas-list-header {
    background-color: #ccc;
}

.hidden-category {
    color: coral;
}
</style>
<template>
    <v-container>

        <v-layout wrap row align-center justify-center>
            <v-flex md12>


                <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>

                <!-- dialog Modalidad -->
                <v-dialog v-model="dialogModalidad" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">Editar Modalidad</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12>
                                        <v-switch label="Modalidad Visible" v-model="editarModalidad.active"
                                            color="orange"></v-switch>
                                    </v-flex>

                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editarModalidad.nombre"
                                            label="Nombre modalidad"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editarModalidad.valor_normal"
                                            label="Valor"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editarModalidad.costo_servicio"
                                            label="Costo Servicio"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editarModalidad.nro_participantes"
                                            label="Nro Participantes"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editarModalidad.limite_edad_inicial"
                                            label="Edad Inicial"></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary darken-1" flat @click="closeEditModalidad">Cancelar</v-btn>
                            <v-btn color="blue darken-1" flat
                                @click="guardarModalidad(editarModalidad.idx)">Listo!</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- dialog Categoria -->
                <v-dialog v-model="dialogCategoria" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">Editar Categoria</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editarCategoria.nombre"
                                            label="Nombre (opc)"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md2>
                                        <v-select v-model="editarCategoria.sexo" item-text="nombre" :return-object=true
                                            :items="sexos" label="Sexo">
                                        </v-select>
                                        <v-menu :ref="'menu_sexos_' + categoria.nombre" :close-on-content-click="false"
                                            v-model="menu_sexos_dialog" :nudge-right="40" lazy
                                            transition="scale-transition" offset-y full-width min-width="290px">
                                        </v-menu>
                                    </v-flex>
                                    <v-flex xs12 sm6 md3>
                                        <v-text-field v-model="editarCategoria.edad_inicial"
                                            label="Edad Inicial"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md3>
                                        <v-text-field v-model="editarCategoria.edad_final"
                                            label="Edad Final"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-switch label="Visible" v-model="editarCategoria.visible"
                                            color="orange"></v-switch>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary darken-1" flat @click="closeEditCategoria">Cancelar</v-btn>
                            <v-btn color="blue darken-1" flat @click="guardarCategoria">Listo!</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-layout v-if="eventoLoading" wrap row align-center justify-center>
                    <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
                </v-layout>
                <!-- formulario -->
                <v-form v-if="!eventoLoading" autocomplete="off">
                    <v-flex pa-2 md11>

                        <h3>1) Datos principales del evento</h3>
                        <v-container grid-list-xl>
                            <v-layout row wrap>

                                <v-flex xs12 md6>
                                    <v-text-field v-validate="'required'" :error-messages="errors.collect('nombre')"
                                        v-model="nombre" name="nombre" label="Nombre del Evento" type="text">
                                    </v-text-field>
                                </v-flex>

                                <v-flex xs12 md6>
                                    <v-select :disabled="!isNewEvent" v-model="organizador" v-validate="'required'"
                                        :error-messages="errors.collect('organizador')" item-text="nombre"
                                        :return-object=true :items="organizadores" label="Organizador"
                                        data-vv-name="organizador" required>
                                    </v-select>
                                    <v-menu ref="menu_organizador" :close-on-content-click="false"
                                        v-model="menu_organizador" :nudge-right="40" lazy transition="scale-transition"
                                        offset-y full-width min-width="290px">
                                    </v-menu>
                                </v-flex>

                                <v-flex xs12 md6>
                                    <v-text-field v-validate="'required'" :error-messages="errors.collect('ciudad')"
                                        v-model="ciudad" name="ciudad" label="Ciudad" type="text">
                                    </v-text-field>
                                </v-flex>

                                <v-flex xs12 md6>
                                    <v-text-field v-validate="'required'" :error-messages="errors.collect('lugar')"
                                        v-model="lugar" name="lugar" label="Lugar" type="text">
                                    </v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>

                        <v-layout>
                            <v-flex pa-3 md-12>
                                <h3>Descripcion</h3>
                               
                              
                                    <tinymce-editor
                                        v-model="descripcion"
                                        api-key="no-api-key"
                                        :init="editorConfig"                    
                                        :initial-value="descripcion"
                                        :tinymceScriptSrc="tinymceUrl"
                                        
                                        
                                    />   
                                    
                            </v-flex>
                        </v-layout>


                        <div class="fechas-panel">
                            <v-layout grid-list-xl row wrap>
                                <v-flex xs12>
                                    <h3>Fechas</h3>
                                </v-flex>
                                <v-flex md6>
                                    <v-menu ref="menuDate" :close-on-content-click="false" v-model="menuDate"
                                        :nudge-right="40" lazy transition="scale-transition" offset-y full-width
                                        min-width="290px">

                                        <v-text-field v-validate="'required'" :error-messages="errors.collect('fecha')"
                                            name="Fecha" slot="activator" v-model="fecha_f" data-vv-name="fecha"
                                            label="Fecha " prepend-icon="event" readonly></v-text-field>

                                        <v-date-picker ref="picker" v-model="fecha" min="2017-01-01" @change="saveDate"
                                            locale="es-ar"></v-date-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex md6>
                                    <v-menu ref="menu" :close-on-content-click="false" v-model="menu2" :nudge-right="40"
                                        :return-value.sync="hora_largada" lazy transition="scale-transition" offset-y
                                        full-width max-width="290px" min-width="290px">
                                        <v-text-field v-validate="'required'"
                                            :error-messages="errors.collect('hora_largada')" slot="activator"
                                            v-model="hora_largada" data-vv-name="hora_largada"
                                            prepend-icon="access_time" label="Hora Largada" readonly></v-text-field>

                                        <v-time-picker format="24hr" v-if="menu2" v-model="hora" full-width
                                            @change="$refs.menu.save(hora)"></v-time-picker>
                                    </v-menu>
                                </v-flex>


                                <!-- Fecha Hora abre inscripcion -->
                                <v-flex md6>
                                    <v-menu ref="menuDate3" :close-on-content-click="false" v-model="menuDate3"
                                        :nudge-right="40" lazy transition="scale-transition" offset-y full-width
                                        min-width="290px">
                                        <v-text-field v-validate="'required'"
                                            :error-messages="errors.collect('fecha_inicio')" name="fecha_inicio"
                                            slot="activator" v-model="fecha_inicio_f" data-vv-name="fecha_inicio"
                                            label="Fecha Inicio Inscripcion" prepend-icon="event"
                                            readonly></v-text-field>
                                        <v-date-picker ref="picker" v-model="fecha_inicio" min="2017-01-01"
                                            @change="saveDateInicio" locale="es-ar"></v-date-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex md6>
                                    <v-menu ref="menu_inicio" :close-on-content-click="false" v-model="menu_inicio"
                                        :nudge-right="40" :return-value.sync="hora_inicio" lazy
                                        transition="scale-transition" offset-y full-width max-width="290px"
                                        min-width="290px">
                                        <v-text-field v-validate="'required'"
                                            :error-messages="errors.collect('hora_inicio')" slot="activator"
                                            v-model="hora_inicio" name="hora_inicio" data-vv-name="hora_inicio"
                                            prepend-icon="access_time" label="Hora Inicio Inscripcion"
                                            readonly></v-text-field>

                                        <v-time-picker format="24hr" v-if="menu_inicio" v-model="hora_inicio" full-width
                                            @change="$refs.menu_inicio.save(hora_inicio)"></v-time-picker>
                                    </v-menu>
                                </v-flex>



                                <v-flex md6>
                                    <v-menu ref="menuDate2" :close-on-content-click="false" v-model="menuDate2"
                                        :nudge-right="40" lazy transition="scale-transition" offset-y full-width
                                        min-width="290px">

                                        <v-text-field v-validate="'required'"
                                            :error-messages="errors.collect('fecha_cierre')" name="fecha_cierre"
                                            slot="activator" v-model="fecha_cierre_f" data-vv-name="fecha_cierre"
                                            label="Fecha Cierre Inscripcion" prepend-icon="event"
                                            readonly></v-text-field>

                                        <v-date-picker ref="picker" v-model="fecha_cierre" min="2017-01-01"
                                            @change="saveDateCierre" locale="es-ar"></v-date-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex md6>
                                    <v-menu ref="menu_cierre" :close-on-content-click="false" v-model="menu_cierre"
                                        :nudge-right="40" :return-value.sync="hora_cierre" lazy
                                        transition="scale-transition" offset-y full-width max-width="290px"
                                        min-width="290px">
                                        <v-text-field v-validate="'required'"
                                            :error-messages="errors.collect('hora_cierre')" slot="activator"
                                            v-model="hora_cierre" data-vv-name="hora_cierre" prepend-icon="access_time"
                                            label="Hora Cierre" readonly></v-text-field>

                                        <v-time-picker format="24hr" v-if="menu_cierre" v-model="hora_cierre" full-width
                                            @change="$refs.menu_cierre.save(hora_cierre)"></v-time-picker>
                                    </v-menu>
                                </v-flex>


                            </v-layout>

                        </div>

                        <v-spacer></v-spacer>

                        <v-flex xs12 md6>
                            <v-text-field v-validate="'required|numeric|min_value:1|max_value:9999'"
                                :error-messages="errors.collect('cupo')" v-model="cupo" name="cupo" label="Cupo Maximo"
                                type="number">
                            </v-text-field>

                        </v-flex>


                        <div class="modalidades-panel">

                            <h3>2) Modalidades</h3>
                            <v-layout wrap row>
                                <v-flex pa-3 md3 xs12>
                                    <v-text-field v-model="modalidad.nombre" label="Nombre" type="text">
                                    </v-text-field>
                                </v-flex>

                                <v-flex pa-3 md2 xs12>
                                    <v-text-field type="number" v-model="modalidad.valor_normal" name="valor"
                                        label="Valor Inscripción">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md2 xs12>
                                    <v-text-field type="number" v-model="modalidad.costo_servicio" name="valor"
                                        label="Costo Servicio">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md2 xs12>
                                    <v-text-field type="number" min="1" v-model="modalidad.nro_participantes"
                                        name="nro_participantes" label="Nro participantes">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md1 xs12>
                                    <v-text-field type="number" min="0" v-model="modalidad.limite_edad_inicial"
                                        name="limite_edad_inicial" label="Limite Edad Inicial">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md1 xs12>
                                    <v-btn small @click="addModalidad" color="secondary">Agregar modalidad +</v-btn>
                                </v-flex>
                            </v-layout>

                            <div class="modalidades-list">
                                <v-layout v-if="this.$vuetify.breakpoint.name != 'xs'" justify-space-around
                                    class="modalidades-list-header  font-weight-bold">
                                    <v-flex pa-2 xs2>
                                        Nombre
                                    </v-flex>
                                    <v-flex class="text-xs-center" pa-2 xs2>
                                        Participantes
                                    </v-flex>
                                    <v-flex class="text-xs-center" pa-2 xs1>
                                        edad Inicial
                                    </v-flex>
                                    <v-flex class="text-xs-center" pa-2 xs2>
                                        Precio
                                    </v-flex>
                                    <v-flex class="text-xs-center" pa-2 xs2>
                                        Visible
                                    </v-flex>
                                    <v-flex pa-2 xs2>
                                        Acc
                                    </v-flex>
                                </v-layout>

                                <span v-if="this.$vuetify.breakpoint.name != 'xs'">
                                    <v-layout justify-space-around wrap row :key="modalidad.nombre"
                                        v-for="(m, key) in modalidades">
                                        <v-flex pa-2 xs2>
                                            {{ m.nombre }}
                                        </v-flex>
                                        <v-flex class="text-xs-center" pa-1 xs2>
                                            {{ m.nro_participantes }}
                                        </v-flex>
                                        <v-flex class="text-xs-center" pa-1 xs1>
                                            {{ m.limite_edad_inicial }}
                                        </v-flex>
                                        <v-flex class="text-xs-center" pa-2 xs2>
                                            ${{ m.valor_normal }} <span v-if="m.costo_servicio > 0"> + ${{
                    m.costo_servicio }}
                                                (Servicio)</span>
                                        </v-flex>
                                        <v-flex class="text-xs-center" pa-2 xs2>
                                            {{ m.active ? 'Si' : 'No' }}
                                        </v-flex>
                                        <v-flex xs2>
                                            <v-btn @click="openEditModalidad(key)" flat icon color="orange">
                                                <v-icon dark>edit</v-icon>
                                            </v-btn>

                                            <v-btn v-if="isNewEvent" @click="removeModalidad(key)" flat icon
                                                color="red">
                                                <v-icon dark>delete</v-icon>
                                            </v-btn>

                                        </v-flex>
                                    </v-layout>
                                </span>

                                <span v-if="this.$vuetify.breakpoint.name == 'xs'">
                                    <v-card justify-space-around wrap row :key="modalidad.nombre"
                                        v-for="(m, key) in modalidades">
                                        <v-flex class="text-xs-center" pa-2 xs12>
                                            <h3>{{ m.nombre }}</h3> ${{ m.valor_normal }} <span
                                                v-if="m.costo_servicio > 0"> + ${{ m.costo_servicio }}
                                                (Servicio)</span>
                                        </v-flex>
                                        <v-flex pa-1 xs12>
                                            Participantes: {{ m.nro_participantes }}
                                        </v-flex>
                                        <v-flex pa-1 xs12>
                                            Edad Inicial {{ m.limite_edad_inicial }}
                                        </v-flex>
                                        <v-flex pa-2 xs12>
                                            Activo:{{ m.active ? 'Si' : 'No' }}
                                        </v-flex>
                                        <v-flex pa-2 pt-2 xs12>
                                            <v-btn @click="openEditModalidad(key)" icon color="orange">
                                                <v-icon dark>edit</v-icon>
                                            </v-btn>
                                            <v-btn v-if="isNewEvent" @click="removeModalidad(key)" flat icon
                                                color="red">
                                                <v-icon dark>delete</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-card>
                                </span>
                            </div>



                        </div>


                        <div class="categorias-panel">
                            <h3>3) Categorias</h3>
                            <v-layout>

                                <v-flex pa-3 md3>
                                    <v-select v-model="categoria.modalidad" item-text="nombre" :return-object=true
                                        :items="modalidades" label="Modalidad">
                                    </v-select>
                                    <v-menu :ref="'menu_modalidad_' + categoria.nombre" :close-on-content-click="false"
                                        v-model="menu_modalidades" :nudge-right="40" lazy transition="scale-transition"
                                        offset-y full-width min-width="290px">
                                    </v-menu>
                                </v-flex>

                                <v-flex pa-3 md1>
                                    <v-select v-model="categoria.sexo" item-text="nombre" :return-object=true
                                        :items="sexos" label="Sexo">
                                    </v-select>
                                    <v-menu :ref="'menu_sexos_' + categoria.nombre" :close-on-content-click="false"
                                        v-model="menu_sexos" :nudge-right="40" lazy transition="scale-transition"
                                        offset-y full-width min-width="290px">
                                    </v-menu>
                                </v-flex>
                                <v-flex pa-3 md2>
                                    <v-text-field type="number" v-model="categoria.edad_inicial" name="edadinicial"
                                        label="Edad Inicial">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md2>
                                    <v-text-field type="number" v-model="categoria.edad_final"
                                        @keyup.enter.native="addCategoria" name="edadfinal" label="Edad Final">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md2>
                                    <v-text-field type="text" v-model="categoria.nombre"
                                        @keyup.enter.native="addCategoria" name="nombre" label="Nombre (opcional)">
                                    </v-text-field>
                                </v-flex>
                                <v-flex pa-3 md2>
                                    <v-select v-model="categoria.visible" item-text="label" :return-value="'value'"
                                        :items="[{ 'label': 'SI', value: true }, { 'label': 'NO', value: false }]"
                                        label="Visible">
                                    </v-select>
                                    <v-menu :ref="'menu_visible_' + categoria.nombre" :close-on-content-click="false"
                                        v-model="menu_visible" :nudge-right="40" lazy transition="scale-transition"
                                        offset-y full-width min-width="290px">
                                    </v-menu>
                                </v-flex>
                                <v-flex pa-3 md2>
                                    <v-btn small @click="addCategoria" color="secondary">Agregar Categoria + </v-btn>
                                </v-flex>
                            </v-layout>

                            <!-- Categorias -->
                            <div class="categorias-list">
                                <v-layout wrap row v-for="(modalidad, key) in modalidades">
                                    <v-flex md12>
                                        <h3>Modalidad: {{ modalidad.nombre }}</h3>

                                    </v-flex>
                                    <br>
                                    <v-flex offset-md3 md3>
                                        Categorias Femeninas
                                    </v-flex>
                                    <v-flex md3>
                                        Categorias Masculinas
                                    </v-flex>
                                    <v-flex md3>
                                        Categorias Mixtas
                                    </v-flex>

                                    <v-flex offset-md3 md3>
                                        <div v-for="(cat, key1) in getCategoriasEnModalidadPorSexo(modalidad, 'FEM')">
                                            <span :class="{ 'hidden-category': !cat.visible }"> {{
                    getCategoriaNombre(cat)
                }}</span>
                                            <v-btn @click="openEditCategoria(modalidad, cat)" flat icon color="orange">
                                                <v-icon title="Editar" dark>edit</v-icon>
                                            </v-btn>
                                            <v-btn title="Eliminar" @click="removeCategoria(modalidad, cat)" flat icon
                                                color="secondary">
                                                <v-icon dark>clear</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>

                                    <v-flex md3>
                                        <div v-for="(cat, key2) in getCategoriasEnModalidadPorSexo(modalidad, 'MASC')">
                                            <span :class="{ 'hidden-category': !cat.visible }"> {{
                    getCategoriaNombre(cat)
                }}</span>
                                            <v-btn title="Editar" @click="openEditCategoria(modalidad, cat)" flat icon
                                                color="orange">
                                                <v-icon dark>edit</v-icon>
                                            </v-btn>
                                            <v-btn title="Eliminar" @click="removeCategoria(modalidad, cat)" flat icon
                                                color="secondary">
                                                <v-icon dark>clear</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>

                                    <v-flex md3>
                                        <div v-for="(cat, key2) in getCategoriasEnModalidadPorSexo(modalidad, 'MIX')">
                                            <span :class="{ 'hidden-category': !cat.visible }"> {{
                    getCategoriaNombre(cat)
                }}</span>
                                            <v-btn title="Editar" @click="openEditCategoria(modalidad, cat)" flat icon
                                                color="orange">
                                                <v-icon dark>edit</v-icon>
                                            </v-btn>
                                            <v-btn title="Eliminar" @click="removeCategoria(modalidad, cat)" flat icon
                                                color="secondary">
                                                <v-icon dark>clear</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>

                                    <v-spacer></v-spacer>
                                    <br>
                                    <br>
                                </v-layout>
                            </div>


                        </div>

                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                        <v-layout>
                            <v-flex class="pa-3">
                                <h3>4) Mensajes de Sistema</h3>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex class="pa-3">
                                <h3>Mensaje Inscripción Correcta</h3>


                                <tinymce-editor
                                    v-model="mensaje_inscripcion_correcta"
                                    api-key="no-api-key"
                                    :init="editorConfig"                    
                                    :initial-value="mensaje_inscripcion_correcta"
                                    :tinymceScriptSrc="tinymceUrl"
                                    />    

                            </v-flex>
                        </v-layout>

                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                        <v-layout>
                            <v-flex class="pa-3">
                                <h3> Mensaje Inscripción Fallida</h3>

                                <tinymce-editor
                                    v-model="mensaje_inscripcion_error"
                                    api-key="no-api-key"
                                    :init="editorConfig"                    
                                    :initial-value="mensaje_inscripcion_error"
                                    :tinymceScriptSrc="tinymceUrl"
                                    />    


                              
                                
                                
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-flex class="pa-3">
                                <h3>EMAIL Inscripcion Registrada</h3>
                                <tinymce-editor
                                    v-model="template_email_inscripcion_registrada"
                                    api-key="no-api-key"
                                    :init="editorConfig"                    
                                    :initial-value="template_email_inscripcion_registrada"
                                    :tinymceScriptSrc="tinymceUrl"
                                    
                                    />    

                                
                            </v-flex>
                        </v-layout>


                        <v-layout>
                            <v-flex class="pa-3">
                                <h3>EMAIL Pago Registrado</h3>
                                <tinymce-editor
                                    v-model="template_email_pago_registrado"
                                    api-key="no-api-key"
                                    :init="editorConfig"                    
                                    :initial-value="template_email_pago_registrado"
                                    :tinymceScriptSrc="tinymceUrl"
                                    />    

                                  
                            </v-flex>
                        </v-layout>

                        


                        <div class="categorias-panel">
                            <v-layout wrap row>
                                <v-flex pa-3 md12>
                                    <h3>5) Medios de Pago</h3>
                                </v-flex>
                                <v-flex pa-3 xs12>
                                    <v-switch label="Usa Pago Online" v-model="usa_pago_online"
                                        color="orange"></v-switch>
                                </v-flex>
                                <v-flex v-if="usa_pago_online" pa-3 xs12>
                                    <v-flex xs12 md6>
                                        <v-text-field type="number"
                                            v-model="configuracion_pago.duracion_inscripcion_pendiente"
                                            name="duracion_inscripcion_pendiente"
                                            label="Duración inscripción Pendiente (Hs)">
                                        </v-text-field>
                                    </v-flex>
                                </v-flex>

                                <v-flex v-if="usa_pago_online" pa-3 xs12>
                                    <div class="subheading">Medios de pago habilitados
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="primary" dark v-on="on">info</v-icon>
                                            </template>
                                            <span>Seleccione aquellos que NO quiere permitir</span>
                                        </v-tooltip>
                                    </div>


                                    <div class="">Todos los medios <span class="font-weight-bold">EXCEPTO</span>: </div>

                                    <v-layout align-center>
                                        <v-checkbox v-for="(value, key) in medios_pago"
                                            v-model="configuracion_pago.tipos_pago_excluidos" :key="key" :label="value"
                                            :value="key">
                                        </v-checkbox>
                                    </v-layout>

                                </v-flex>

                            </v-layout>
                        </div>

                        <v-layout wrap row>
                            <h3>6) Otras Configuraciones</h3>
                            <v-flex pa-3 md12>
                            </v-flex>
                            <v-flex pa-3 md6>
                                <h4>Usa Talle</h4>
                                <v-switch label="Usa Talles" v-model="usa_talle_remera" color="orange"></v-switch>
                                </v-flex>
                            <v-flex pa-3 md6>
                                    <h4>Asignación Números</h4>
                                    <v-switch label="Asignación Números Manual" v-model="asignacion_manual" color="orange"></v-switch>
                                </v-flex>
                        </v-layout>
                        <div class="categorias-panel">

                            <h3>7) Visibilidad</h3>
                            <v-layout wrap row>
                                <v-flex pa-3 md6>
                                    <h4>Publicado</h4>
                                    <v-switch label="Publicado" v-model="visible" color="orange"></v-switch>
                                </v-flex>
                                <v-flex pa-3 md6>
                                    <h4>Público / Privado</h4>
                                    <v-switch label="Privado" v-model="privado" color="orange"></v-switch>
                                </v-flex>
                                <v-flex pa-3 md6>

                                </v-flex>
                                <v-flex v-if="privado" pa-3 md6>
                                    <h4>Código para URL</h4>
                                    <v-text-field type="text" v-model="codigo_acceso" name="codigo_acceso"
                                        label="Codigo para URL privada(sin espacios y/o caracteres especiales)">
                                    </v-text-field>
                                    <p>{{ urlPrivada }}</p>
                                    <v-btn color="primary" flat small @click="copyToClipboard(urlPrivada)">Copiar URL
                                        para compartir</v-btn>

                                    <br>

                                    <p>{{ urlPrivadaInfo }}</p>
                                    <v-btn color="primary" flat small @click="copyToClipboard(urlPrivadaInfo)">Copiar URL
                                        para compartir</v-btn>
                                </v-flex>
                            </v-layout>

                        </div>

                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                <div class="disciplinas-panel">
                    <v-layout  justify-start wrap>
                            <v-flex class="pa-3" xs12>
                                <h3>8) Disciplinas</h3>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                                <h4>Etiquetado con:</h4>
                                 <v-chip v-if="this.disciplinas_evento.length == 0">Este evento aun no tiene disciplinas, añada algunas!</v-chip> 
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                                    <v-chip v-for="(disi, index) in disciplinas_evento" 
                                        :key="disi.id" 
                                        color="orange" 
                                        text-color="black" close
                                        @input="quitarDisciplina(index)">
                                    
                                    #{{ disi.nombre }}</v-chip>
                            </v-flex>
                    </v-layout>
                    <v-spacer></v-spacer>
                    <!-- Disciplinas -->
                    <v-layout justify-start  wrap>
                        <v-flex class="pa-3" xs12>
                                <h4>Agregar etiqueta:</h4>
                                
                        </v-flex>
                        <v-flex class="pa-3" xs12>                      
                                <v-chip v-if="this.disciplinas.length == 0"> Aun no existen disciplinas, puede creerlas desde la barra de herramientas </v-chip> 
                                <v-chip v-for="(disciplina, index) in disciplinas" 
                                    :key="disciplina.id" label 
                                    text-color="white"
                                    color="secondary"  
                                    @click="agregarDisciplina(disciplina)">
                                    <v-icon left>add</v-icon>
                                        {{ disciplina.nombre }}  
                                </v-chip>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>

                        <v-spacer></v-spacer>
                    <div>
                        <v-btn @click="() => $router.go(-1)">&lt; Volver</v-btn>


                        <v-btn v-if="this.eventoId != null" :loading="actualizarLoading" @click="submit(true)"
                            color="orange">Guardar y
                            Finalizar<v-icon right dark>save</v-icon> </v-btn>


                        <v-btn :loading="actualizarLoading" @click="submit(false)" color="orange">Guardar y Configurar
                            imagen<v-icon right dark>save</v-icon> </v-btn>

                        <v-btn v-if="isEditting && !privado" :to="'/informacion-evento/' + eventoId">Ver Pantalla del evento</v-btn>
                        <v-btn v-if="isEditting && privado" :to="'/informacion-evento/' + eventoId  + '/' + codigo_acceso ">Ver Pantalla del evento</v-btn>

                    </div>
                </v-form>


            </v-flex>

        </v-layout>
        {{ tinymceUrl}}
    </v-container>
</template>

<script>
import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
import {   mapState  } from 'vuex'
import api from '@/api/evento'
 
import Editor from '@tinymce/tinymce-vue'
import config from '../../../config';
 
Validator.localize('es', es);
Vue.use(VeeValidate);


const MEDIOS_PAGO = {
    ticket: 'Rapipago / Pago Facil',
    credit_card: 'Tarjetas de Crédito',
    debit_card: 'Tarjetas de Débito'
}


export default {
    components: {
    'tinymce-editor': Editor
  },
    data: vm => ({        
        tinymceUrl:  config.host_url + "/tinymce/tinymce.min.js",
        editorConfig: {
            language: "es",
            height: 300,
            menubar: false, 
            plugins: '    autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste help  ',
            toolbar: " undo redo |fontsize  blocks| bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor | removeformat | link",
            font_size_formats: '10px 12px 14px 18px 24px 36px',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            block_formats: 'Párrafo=p; Título=h1; Subtítulo=h2; Encabezado 3=h3; Encabezado 4=h4',
     
        }, 
        medios_pago: MEDIOS_PAGO,
        eventoLoading: false,
        actualizarLoading: false,
        evento: {},
        eventoId: null,
        nombre: '',
        ciudad: '',
        lugar: '',
        descripcion: '',
        mensaje_inscripcion_correcta: 'La inscripcion se registro correctamente',
        mensaje_inscripcion_error: 'Algo falló. Comuniquese con FinishtTime, o intente nuevamente más tarde.',
        //deslinde:'',
        organizador: '',
        modalidades: [],
        categorias: [],
        categoria: { nombre: '', sexo: '', modalidad: null, edad_inicial: '', edad_final: '' },
        menu_sexos: null,
        menu_visible: null,
        menu_sexos_dialog: null,
        menu_modalidades: null,
        modalidad: {
            nombre: '',
            valor_normal: '',
            costo_servicio: 0,
            nro_participantes: 1,
            limite_edad_inicial: 0,
            active: true,
            categorias: []
        },
        sexos: ['MASC', 'FEM', 'MIX'],
        menuDate: '',
        menuDate2: '',
        menuDate3: '',
        fecha_f: '',
        fecha_cierre_f: '',
        hora_cierre: '',
        fecha_inicio: '',
        fecha_inicio_f: '',
        hora_inicio: '',
        hora_largada: '',
        fecha: new Date().toISOString().substr(0, 10),
        fecha_cierre: new Date().toISOString().substr(0, 10),
        hora: '',
        visible: true,
        privado: false,
        asignacion_manual: false,
        codigo_acceso: '',
        cupo: null,
        menu2: null,
        menu_cierre: null,
        menu_inicio: null,
        menu_organizador: '',
        mensaje_resultado: '',
        template_email_inscripcion_registrada: '',
        template_email_pago_registrado: '',
        usa_pago_online: false,
        tipos_pago_excluidos: [],
        configuracion_pago: {
            tipos_pago_excluidos: [],
            duracion_inscripcion_pendiente: 48
        },
        usa_talle_remera: false,
        dialog: false,

        editarModalidad: {
            nombre: '',
            valor_normal: '',
            nro_participantes: 1,
            limite_edad_inicial: 0,
            costo_servicio: 0,
            active: true,
            idx: 0,
        },
        categoriaSeleccionada: null,
        editarCategoria: {
            nombre: '',
            sexo: '',
            edad_inicial: '',
            edad_final: '',
            visible: true
        },
        dialogModalidad: false,
        dialogCategoria: false,
        disciplinas_evento:[],
        
    }),
    props: {

    },
    computed: {
        ...mapState({
            organizadores: state => state.eventos.organizadores,
            disciplinas: state => state.disciplinas.disciplinas_todas,
            steps: state => [
                {
                    text: 'Eventos',
                    disabled: false,
                    href: '/#/admin/evento/lista'
                },
                {
                    text: "Editar Evento",
                    disabled: true,
                    href: '#'
                }
            ]
        }),
        isNewEvent() {
            return this.eventoId == null
        },
        isEditting() {
            return this.eventoId != null
        },
        computedIdEvento() {
            if (this.isEditting) {
                return this.eventoId
            } else {
                return '[ID-EVENTO]'
            }
        },
        baseUrl() {
            return window.location.origin
        },
        urlPrivada() {
            return this.baseUrl + '/#/inscripcion/' + this.computedIdEvento + '/' + this.codigo_acceso
        },
        urlPrivadaInfo(){
            return this.baseUrl + '/#/informacion-evento/' + this.computedIdEvento + '/' + this.codigo_acceso
        }
    },

    mounted() {
        scroll(0, 0);

        if (this.$route.params.id) {
            this.eventoId = this.$route.params.id
            this.getDataFromApi()
        }

        // this.getDataFromApi()
    },
    watch: {
        menuDate(val) {
            //  val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        fecha(val) {
            this.fecha_f = this.formatDate(this.fecha)
        },
        fecha_cierre(val) {
            this.fecha_cierre_f = this.formatDate(this.fecha_cierre)
        },

        fecha_inicio(val) {
            this.fecha_inicio_f = this.formatDate(this.fecha_inicio)
        },

        evento(evento) {
            this.nombre = evento.nombre;
            this.ciudad = evento.ciudad;
            this.lugar = evento.lugar;
            this.descripcion = evento.descripcion;
            this.organizador = evento.organizador;

            let partes = evento.fecha_hora.split(' ')
            this.fecha = partes[0];
            this.fecha_f = this.formatDate(this.fecha);
            this.hora = partes[1];
            this.hora_largada = partes[1];

            partes = evento.fin_inscripcion.split(' ')
            this.fecha_cierre = partes[0];
            this.fecha_cierre_f = this.formatDate(this.fecha_cierre);
            this.hora_cierre = partes[1];

            partes = evento.fecha_inicio_inscripcion.split(' ')
            this.fecha_inicio = partes[0];
            this.fecha_inicio_f = this.formatDate(this.fecha_inicio);
            this.hora_inicio = partes[1];

            this.modalidades = evento.modalidades;
            this.mensaje_inscripcion_correcta = evento.mensaje_inscripcion_correcta;
            this.mensaje_inscripcion_error = evento.mensaje_inscripcion_error;
            // this.deslinde = evento.deslinde;
            this.usa_talle_remera = evento.usa_talle_remera;
            this.usa_pago_online = evento.usa_pago_online;

            if (evento.configuracion_pago) {
                this.configuracion_pago.duracion_inscripcion_pendiente = evento.configuracion_pago.duracion_inscripcion_pendiente;
                this.configuracion_pago.tipos_pago_excluidos = evento.configuracion_pago.tipos_pago_excluidos ? evento.configuracion_pago.tipos_pago_excluidos : [];
            }


            this.template_email_inscripcion_registrada = evento.template_email_inscripcion_registrada;
            this.template_email_pago_registrado = evento.template_email_pago_registrado;
            this.visible = evento.visible;
            this.privado = evento.privado;
            this.asignacion_manual = evento.asignacion_manual;
            this.codigo_acceso = evento.codigo_acceso;
            this.cupo = evento.cupo;
            this.disciplinas_evento = evento.disciplinas;
        }
    },
    created() {
        this.$store.dispatch('eventos/getOrganizadores');
        this.$store.dispatch('disciplinas/getDisciplinas');
    },
    methods: {
        copyToClipboard(text) {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            alert('Texto copiado al portapapeles');
            return false;
        },
        addModalidad() {
            if (this.modalidad.nombre.length == 0) {
                alert("Debe ingresar un nombre de modalidad");
                return false;
            }

            if (!this.modalidad.valor_normal) {
                alert("Debe ingresar un valor");
                return false;
            }


            let modalidad = this.modalidades.find((m) => m.nombre === this.modalidad.nombre)
            if (modalidad)
                return false;
            this.modalidades.push({
                nombre: this.modalidad.nombre,
                valor_normal: this.modalidad.valor_normal,
                costo_servicio: this.modalidad.costo_servicio,
                nro_participantes: this.modalidad.nro_participantes,
                limite_edad_inicial: this.modalidad.limite_edad_inicial,
                active: true,
                categorias: []
            })

            this.modalidad = {
                nombre: '',
                valor_normal: '',
                costo_servicio: 0,
                nro_participantes: 1,
                limite_edad_inicial: 0,
                active: true
            }
        },

        removeModalidad(idx) {
            this.modalidades.splice(idx, 1);
        },


        openEditModalidad(idx) {
            this.editarModalidad.nombre = this.modalidades[idx].nombre;
            this.editarModalidad.valor_normal = this.modalidades[idx].valor_normal;
            this.editarModalidad.costo_servicio = this.modalidades[idx].costo_servicio;
            this.editarModalidad.nro_participantes = this.modalidades[idx].nro_participantes;
            this.editarModalidad.limite_edad_inicial = this.modalidades[idx].limite_edad_inicial;
            this.editarModalidad.active = this.modalidades[idx].active;
            this.editarModalidad.idx = idx;
            this.dialogModalidad = true;
        },

        guardarModalidad(idx) {
            this.modalidades[idx].nombre = this.editarModalidad.nombre;
            this.modalidades[idx].valor_normal = this.editarModalidad.valor_normal;
            this.modalidades[idx].costo_servicio = this.editarModalidad.costo_servicio;
            this.modalidades[idx].nro_participantes = this.editarModalidad.nro_participantes;
            this.modalidades[idx].limite_edad_inicial = this.editarModalidad.limite_edad_inicial;

            this.modalidades[idx].active = this.editarModalidad.active;
            this.dialogModalidad = false;
        },

        closeEditModalidad(idx) {
            this.dialogModalidad = false;
        },

        closeEditCategoria(idx) {
            this.dialogCategoria = false;
        },

        guardarCategoria(modalidad) {
            this.categoriaSeleccionada.nombre = this.editarCategoria.nombre
            this.categoriaSeleccionada.sexo = this.editarCategoria.sexo
            this.categoriaSeleccionada.edad_inicial = this.editarCategoria.edad_inicial
            this.categoriaSeleccionada.edad_final = this.editarCategoria.edad_final
            this.categoriaSeleccionada.visible = this.editarCategoria.visible
            this.dialogCategoria = false
        },

        addCategoria() {
            if (!this.categoria.modalidad) {
                alert("Debe seleccionar una modalidad");
                return false;
            }
            if (!this.categoria.sexo) {
                alert("Debe seleccionar un sexo");
                return false;
            }
            if (!this.categoria.edad_inicial) {
                alert("Debe ingresar una edad inicial");
                return false;
            }

            if (!this.categoria.edad_final) {
                alert("Debe ingresar una edad final");
                return false;
            }

            let cat = this.categoria;
            let modalidad = this.modalidades.find((m) => m.nombre === this.categoria.modalidad.nombre);
            if (modalidad) {
                if (cat.edad_inicial != '' && cat.edad_final == '') {
                    this.categoria.edad_final = '+'
                } else {
                    if (parseInt(cat.edad_inicial) >= parseInt(cat.edad_final)) {
                        alert("La edad final debe ser mayor que la edad inicial")
                        return;
                    }
                }

                //habria que validar que el rango ingresado no se superponga con ningun otro

                modalidad.categorias.push({
                    nombre: this.categoria.nombre,
                    sexo: this.categoria.sexo,
                    edad_inicial: this.categoria.edad_inicial,
                    edad_final: this.categoria.edad_final,
                    visible: this.categoria.visible
                })

                this.categoria.edad_inicial = parseInt(this.categoria.edad_final) + 1
                this.categoria.edad_final = ''
                this.categoria.nombre = ''
            }
        },

        removeCategoria(modalidad, cat) {
            this.$set(cat, 'borrado', true);
        },

        openEditCategoria(modalidad, categoria) {
            this.categoriaSeleccionada = categoria;
            this.editarCategoria.nombre = categoria.nombre;
            this.editarCategoria.sexo = categoria.sexo;
            this.editarCategoria.edad_inicial = categoria.edad_inicial;
            this.editarCategoria.edad_final = categoria.edad_final;
            this.editarCategoria.visible = categoria.visible;
            this.dialogCategoria = true;
        },


        getCategoriasEnModalidadPorSexo(modalidad, sexo) {
            return modalidad.categorias.filter((c) => c.sexo === sexo && !c.borrado)
        },
        getCategoriaNombre(cat) {
            let edadRango = cat.sexo + cat.edad_inicial + '-' + cat.edad_final;
            return (cat.nombre) ? cat.nombre + '(' + edadRango + ')' : edadRango;
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate(date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        saveDate(date) {
            this.$refs.menuDate.save(date)
        },
        saveDateCierre(date) {
            this.$refs.menuDate2.save(date)
        },
        saveDateInicio(date) {
            this.$refs.menuDate3.save(date)
        },


        getFechaHora() {
            return this.fecha + " " + this.hora
        },

        getFechaHoraCierre() {
            return this.fecha_cierre + " " + this.hora_cierre
        },

        getFechaHoraInicioInscripcion() {
            return this.fecha_inicio + " " + this.hora_inicio
        },


        getDataFromApi() {
            this.eventoId = this.$route.params.id;
            api.getBackofficeEventoById(this.eventoId).then(evento => {
                this.evento = evento;
                this.eventoLoading = false;
            })
        },

        submitAndFinish() {
            this.submit(true);
        },


        handleSaveError(error) {
            console.log(error);
            this.exito = false;
            this.$store.commit('app/SET_MESSAGE', "Ocurrio un error al guardar el evento. Intente nuevamente.");
            this.$store.commit('app/SET_TYPE', 'error');
            this.$store.commit('app/SET_SHOW', true);
        },


        handleSaveSuccess(res, finalizar = false) {
            this.exito = true;
            this.$store.commit('app/SET_MESSAGE', res.message);
            this.$store.commit('app/SET_TYPE', 'success');
            this.$store.commit('app/SET_SHOW', true);
            if (finalizar) {
                this.$router.push('/admin/evento/lista');
            } else {
                this.$router.push('/admin/evento/' + res.results.id + '/configurar-imagen');
            }
        },





        submit(finalizar) {
            this.$validator.validateAll().then((isOk) => {
                if (isOk) {
                    // validar manualmente que cada modalidad tenga al menos una categoria

                    let modalidadesVacias = this.modalidades.filter((modalidad) => {
                        return modalidad.categorias.length === 0
                    })
                    if (this.modalidades.length <= 0) {
                        alert("Debe crear al menos alguna modalidad!!");
                    }
                    if (modalidadesVacias.length > 0) {
                        alert(`Las modalidades: ${modalidadesVacias.map((m) => m.nombre).join(',')} no poseen categias. Debe Configurar alguna.`);
                        return false;
                    }

                    //armo el json
                    let evento = {
                        nombre: this.nombre,
                        ciudad: this.ciudad,
                        lugar: this.lugar,
                        fecha_hora: this.getFechaHora(),
                        cierre_inscripcion: this.getFechaHoraCierre(),
                        fecha_inicio_inscripcion: this.getFechaHoraInicioInscripcion(),
                        organizador: this.organizador.id,
                        descripcion: this.descripcion,
                        modalidades: this.modalidades,
                        mensaje_inscripcion_correcta: this.mensaje_inscripcion_correcta,
                        mensaje_inscripcion_error: this.mensaje_inscripcion_error,
                        //deslinde:this.deslinde,
                        template_email_inscripcion_registrada: this.template_email_inscripcion_registrada,
                        template_email_pago_registrado: this.template_email_pago_registrado,
                        usa_pago_online: this.usa_pago_online,
                        configuracion_pago: this.configuracion_pago,
                        usa_talle_remera: this.usa_talle_remera,
                        visible: this.visible,
                        privado: this.privado,
                        asignacion_manual: this.asignacion_manual,
                        codigo_acceso: this.codigo_acceso,
                        cupo: this.cupo,
                        disciplinas:this.disciplinas_evento,
                    }
                    this.actualizarLoading = true;


                    if (this.eventoId == null) {
                        api.crearEvento(evento).then(res => {
                            this.actualizarLoading = false;
                            if (res.status == 201) {
                                this.handleSaveSuccess(res, false)
                            }
                        })
                            .catch(error => {
                                this.handleSaveError(error)
                            })
                    } else {
                        api.actualizarEvento(this.eventoId, evento).then(res => {
                            this.actualizarLoading = false;
                            if (res.status == 200) {
                                this.handleSaveSuccess(res, finalizar)
                            }
                        })
                            .catch(error => {
                                this.handleSaveError(error)
                            })
                    }


                }
            })

        },

        agregarDisciplina(disciplina) {
       	let existe = this.disciplinas_evento.find((dis) => dis.id === disciplina.id);
            if (existe)
                return false;
            this.disciplinas_evento.push(disciplina);
        },

        quitarDisciplina(index) {
        	this.disciplinas_evento.splice(index, 1);
            
       }

    }
}
</script>
