<template>
    <div>
        <v-flex>

            <v-card>
                <v-card-title>
                    <v-layout row wrap >
                        <v-flex xs12 md5 ma-1>
                            <InputSearch :label="`Buscar x DNI, nombre, etc`" @search="search">
                            </InputSearch>                            
                        </v-flex>
                        
                        <v-flex xs12 md3 ma-1>
                            <v-select
                                v-model="selectedModalidadFilter"
                                item-text="nombre"
                                item-value="id"
                                :items="comboModalidades"
                                label="Modalidad"       
                                                         
                            ></v-select>                         
                        </v-flex>
                        <v-flex xs12 md3 ma-1>
                            <v-select
                                v-model="selectedStatesFilter"
                                :items="stateFilterOptions"
                                label="Estado"
                            ></v-select>                         
                        </v-flex>

                    </v-layout>
                </v-card-title>


                <div>

                    <div class="tools">
                        <v-btn color="primary" small flat @click="openSendEmailDialog()">Email a Seleccionados</v-btn>
                        <v-btn color="primary" small flat @click="openSendEmailDialog(true)"> Enviar email a todos</v-btn>

                      
                        <!-- <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                color="secondary"
                                dark
                                v-on="on"
                                small
                                >
                                Descargas <v-icon right dark>cloud_download</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-tile>
                                    <v-list-tile-title>Planilla Rufus</v-list-tile-title>
                                </v-list-tile>
                                <v-list-tile>
                                    <v-list-tile-title>Listado Inscriptos</v-list-tile-title>
                                </v-list-tile>
                            </v-list>
                            </v-menu> -->
                        </div>

                    <InscripcionesTabla :items="items" :evento="evento" 
                        @abrirMarcarPagada="abrirMarcarPagada"
                        @abrirEditarInscripcion="abrirEditarInscripcion"
                        @abrirVerInscripcion="abrirVerInscripcion" @abrirBorrarInscripcion="abrirBorrarInscripcion"
                        @refrescarDatos="() => { this.getDataFromServer() }" @paginationChange="paginationChange"
                        :loading="loading" :totalItems="totalItems" ref="inscripcionesTabla" />

                </div>
            </v-card>


        </v-flex>

        <EditarInscripcionDialog ref="dialogInscripcion" :inscripcion="inscripcionEditar"
            @refrescarDatos="() => { this.getDataFromApi() }" />

        <VerInscripcionDialog ref="dialogVerInscripcion" />

        <BorrarInscripcionDialog :inscripcion="inscripcionBorrar" ref="dialogBorrarInscripcion"
            @refrescarDatos="() => { this.getDataFromApi() }" />

        <SendEmailDialog :eventId="evento.id" :inscriptions="inscriptionsSelected" :allSelected="allSelected" :query="query"
            ref="dialogSendEmail" />

        <MarcarPagadaDialog ref="dialogMarcarPagada" :inscripcion="inscripcionEditar"
        @refrescarDatos="() => { this.getDataFromApi() }"/>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/inscripcion'
import EditarInscripcionDialog from '../../../../components/inscripcion/editar-inscripcion/EditarInscripcionDialog.vue';
import VerInscripcionDialog from '../../../../components/inscripcion/ver-inscripcion/VerInscripcionDialog.vue';
import BorrarInscripcionDialog from '../../../../components/inscripcion/borrar-inscripcion/BorrarInscripcionDialog.vue';
import SendEmailDialog from '../../../../components/inscripcion/email/SendEmailDialog.vue';
import MarcarPagadaDialog from '../../../../components/inscripcion/inscripcion-pagada/MarcarPagadaDialog.vue';


import InscripcionesTabla from './InscripcionesTabla.vue'
import debounce from '@/directives/debounce';
import InputSearch from '@/components/common/InputSearch.vue';

const defaultStatesFilter = ['PAGO_REALIZADO', 'PAGO_BONIFICADO'];
const stateFilterOptions = [
                {
                    text: 'Todos',
                    value: [],
                },               
                {
                    text: 'Pagado/Bonificado',
                    value: ['PAGO_REALIZADO','PAGO_BONIFICADO'],
                },
                {
                    text: 'Pagado',
                    value: ['PAGO_REALIZADO'],
                },
                {
                    text: 'Bonificado',
                    value: ['PAGO_BONIFICADO'],
                },
                {
                    text: 'Pago Pendiente',
                    value: ['PAGO_PENDIENTE'],
                } 
            ]
export default {
     
    directives: { debounce },
    components: {
        "EditarInscripcionDialog": EditarInscripcionDialog,
        "VerInscripcionDialog": VerInscripcionDialog,
        "BorrarInscripcionDialog": BorrarInscripcionDialog,
        "SendEmailDialog": SendEmailDialog,
        "InscripcionesTabla": InscripcionesTabla,
        "MarcarPagadaDialog": MarcarPagadaDialog,
        "InputSearch": InputSearch
    },
    data() {
        return {
            selectedStatesFilter: stateFilterOptions[1],
            selectedModalidadFilter: null,
            inscriptionsSelected: [

            ],
            allSelected: true,
            model: null,
            editarInscripcion: {
                state: '',
            },
            dialogInscripcion: false,
            inscripcionEditar: {},
            inscripcionBorrar: {},
            loadingEvento: true,
            loading: false,
            personaEditar: {},
            pagination: {
                search: '',
                descending: false,
                page: 1,
                rowsPerPage: 20,
                sortBy: "id",
                totalItems: 0,
                filters: {
                    estados: JSON.stringify(stateFilterOptions[1].value),
                    modalidad: ''
                },
            },

            items: [],
            totalItems: 0,
            stateFilterOptions: stateFilterOptions
        }
    },
    computed: mapState({
        comboModalidades: state =>  [{id:0, nombre: "Todas"},...state.eventos.eventoActual.modalidades],
        evento: state => state.eventos.eventoActual,
        modalidades: state => state.eventos.eventoActual.modalidades,
        steps: state => [
            {
                text: 'Eventos',
                disabled: false,
                href: '/#/backoffice/events/list'
            },
            {
                text: state.eventos.eventoActual.nombre,
                disabled: true,
                href: '/#/backoffice/event/' + state.eventos.eventoActual.id
            }
        ],
        query: function () {
            let filters = {
                search: this.pagination.search,
                filters: {
                    estados: this.selectedStatesFilter.value
                }
            }
            if(this.selectedModalidadFilter){
                filters.filters.modalidad = this.selectedModalidadFilter;
            }
            return filters;
        }

    }),
    created() {
        scroll(0, 0);
        let eventoId = this.$route.params.id;
        this.$store.dispatch('eventos/getEventoById', eventoId).then((res) => {
            this.loadingEvento = false;

        })

    },
    watch: {
        selectedStatesFilter(){
            this.pagination.filters.estados = JSON.stringify(this.selectedStatesFilter);
            this.pagination.page = 1;
        },
        selectedModalidadFilter() {
             this.pagination.filters.modalidad = this.selectedModalidadFilter;
            this.pagination.page = 1;
         },
        pagination: {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        },

    },
    mounted() {

    },
    methods: {
        paginationChange(pagination) {
            this.pagination = { ...this.pagination, ...pagination }
        },
        search(value) {
            this.pagination.search = value;
            this.pagination.page = 1;
            this.getDataFromApi();
        },        
        

        getDataFromApi() {
            this.loading = true;
            let eventoId = this.$route.params.id;
            api.getInscriptosEventoOrganizadorId(eventoId, this.pagination).then((res) => {
                this.items = res.data;
                this.totalItems = res.total;
                this.loading = false;
 
                this.inscriptionsSelected = res.data
            })
        },

        abrirEditarPersonaDialog(persona) {
            this.$refs.dialogPersona.abrirDialog();
            this.personaEditar = persona;
        },

        abrirEditarInscripcion(inscripcion) {
            this.inscripcionEditar = JSON.parse(JSON.stringify(inscripcion));
            this.$refs.dialogInscripcion.abrirDialog();
        },

        abrirVerInscripcion(inscripcion) {
            this.$refs.dialogVerInscripcion.abrirDialog(inscripcion);
        },

        abrirMarcarPagada(inscripcion) {
            this.inscripcionEditar = JSON.parse(JSON.stringify(inscripcion));

            this.$refs.dialogMarcarPagada.abrirDialog();
        },

        abrirBorrarInscripcion(inscripcion) {
            this.inscripcionBorrar = JSON.parse(JSON.stringify(inscripcion));
            this.$refs.dialogBorrarInscripcion.abrirDialog();
        },
        openSendEmailDialog(all = false) {
            this.allSelected = all;
            if (!all) {
                this.inscriptionsSelected = this.$refs.inscripcionesTabla.getSelected();
            }
            this.$refs.dialogSendEmail.openDialog();
        },

    }

}
</script>                   
